import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { searchResultsState } from 'Apps/store';

const AppsEmpty = () => {
  const { query } = useRecoilValue(searchResultsState);
  return (
    <Box
      textAlign="center"
      display="flex"
      flex="auto"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box p={2}>
        {query
          ? (<Typography color="textSecondary" variant="h5">Looks like there are no apps you are looking for.</Typography>)
          : (<Typography color="textSecondary" variant="h5">Looks like there are no apps yet.</Typography>)}
      </Box>
      <Typography color="textSecondary">Try <code>thingware apps publish</code> to publish your app.</Typography>
    </Box>
  );
};

export default AppsEmpty;
