import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import useProjectId from 'hooks/useProjectId';
import { useSetRecoilState } from 'recoil';
import { getWebhookPendingState, webhookState } from '../store';

const useGetOne = () => {
  const projectId = useProjectId();
  const firebase = useFireplaceFirebase();
  const setWebhook = useSetRecoilState(webhookState);
  const setGetOnePending = useSetRecoilState(getWebhookPendingState);

  const getOne = async () => {
    setGetOnePending(true);

    try {
      const res = await firebase.firestore()
        .collection(`projects/${projectId}/integrations`)
        .doc('webhook')
        .get();

      setWebhook(res.data());

      return res.data();
    } catch (err) {
      setWebhook(null);
      throw err;
    } finally {
      setGetOnePending(false);
    }
  };

  return [getOne];
};

export default useGetOne;
