/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
} from '@material-ui/core';

const NumberConfig = ({
  label,
  description,
  value,
  onChange,
  required,
  pending
}) => {
  const [val, setVal] = useState(value.toString());
  const isSmall = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const isMedium = useMediaQuery(({ breakpoints }) => breakpoints.only('md'));

  const showLabel = (!isSmall && !isMedium);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange = (v) => {
    setVal(v);

    if (!v || isNaN(+v)) {
      return;
    }

    onChange(+v);
  };

  return (
    <ListItem dense>
      <Box display="flex" width="100%">
        {showLabel && (
          <Box width={240}>
            <ListItemText primary={`${label} ${required ? '*' : ''}`} />
          </Box>
        )}
        <Box flex="auto">
          <TextField
            label={!showLabel && label}
            required={required}
            helperText={description || ' '}
            value={val}
            onChange={e => handleChange(e.target.value)}
            fullWidth
            InputProps={{
              type: 'number',
              endAdornment: pending && (
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} />
                </Box>
              ),
            }}
          />
        </Box>
      </Box>
    </ListItem>
  );
};

NumberConfig.propTypes = {
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  pending: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number,
  description: PropTypes.string,
};

NumberConfig.defaultProps = {
  required: false,
  pending: false,
  label: '',
  value: 0,
  description: '',
};

export default NumberConfig;
