import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import useRouter from 'use-react-router';
import { useSnackbarMessage } from 'Snackbar/store';
import useProjectId from 'hooks/useProjectId';
import { useDeletePending, useDeleteHit } from '../store';

const useDelete = () => {
  const projectId = useProjectId();
  const [deleteHit] = useDeleteHit();
  const firebase = useFireplaceFirebase();
  const { deviceId } = useRouter().match.params;
  const [, setDeletePending] = useDeletePending();
  const [, setSnackbarMessage] = useSnackbarMessage();

  return [async () => {
    setDeletePending(true);

    try {
      await firebase.firestore()
        .collection(`projects/${projectId}/devices`)
        .doc(deviceId)
        .delete();
      deleteHit(deviceId);
      setSnackbarMessage('Device deleted.');
    } catch (err) {
      setSnackbarMessage('Could not delete device. Please try again.');
    } finally {
      setDeletePending(false);
    }
  }];
};

export default useDelete;
