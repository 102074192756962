import { useSnackbarMessage } from 'Snackbar/store';
import useApiClient from 'hooks/useApiClient';
import {
  useDisplayName,
  useCreatePending,
  useCreateDialogOpen,
  useAddOneHit
} from '../store';

const useCreate = () => {
  const apiClient = useApiClient();
  const [addOne] = useAddOneHit();
  const [, setCreateDialogOpen] = useCreateDialogOpen();
  const [, setSnackbarMessage] = useSnackbarMessage();
  const [displayName] = useDisplayName();
  const [, setCreatePending] = useCreatePending();

  const create = async () => {
    setCreatePending(true);
    try {
      const { data: { tokenId } } = await apiClient.post('/tokens/v1/', { displayName });
      addOne({ objectID: tokenId, displayName });
      setSnackbarMessage('Developer token created.');
    } catch (err) {
      setSnackbarMessage('Could not create developer token. Please try again.');
    } finally {
      setCreatePending(false);
      setCreateDialogOpen(false);
    }
  };

  return [create];
};

export default useCreate;
