/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableHead, TableRow, TableCell, TableBody,
  Typography, Link, Table, ListItem,
  List, ListItemIcon, Checkbox, ListItemText,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(2),
  }
}));

const MarkdownHeading = ({ level, children }) => {
  const { header } = useStyles();

  const variant = {
    1: 'h5',
    2: 'h6',
    3: 'subtitle1',
    4: 'subtitle2',
  }[level] ?? 'h6';

  return (<Typography className={header} gutterBottom variant={variant}>{children}</Typography>);
};

const MarkdownListItem = ({ children, checked }) => {
  const hasCheckbox = checked === true || checked === false;

  return (
    <>
      <ListItem>
        {hasCheckbox && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked}
              tabIndex={-1}
              disableRipple
              inputProps={{ readOnly: true }}
            />
          </ListItemIcon>
        )}
        <ListItemText primary={children} />
      </ListItem>
      <Divider component="li" />
    </>
  );
};

const MarkdownTableCell = ({ children, isHeader }) => (
  <TableCell>
    <Typography variant={isHeader ? 'h6' : 'body1'}>{children}</Typography>
  </TableCell>
);

const MarkdownList = ({ children }) => (<List>{children}</List>);
const MarkdownBreakLine = () => (<Divider />);
const MarkdownTable = ({ children }) => (<Table size="small">{children}</Table>);
const MarkdownParagraph = ({ children }) => (<Typography>{children}</Typography>);
const MarkdownTableRow = ({ children }) => (<TableRow>{children}</TableRow>);
const MarkdownTableBody = ({ children }) => (<TableBody>{children}</TableBody>);
const MarkdownTableHead = ({ children }) => (<TableHead>{children}</TableHead>);

const renderers = {
  heading: MarkdownHeading,
  paragraph: MarkdownParagraph,
  link: Link,
  list: MarkdownList,
  listItem: MarkdownListItem,
  table: MarkdownTable,
  tableHead: MarkdownTableHead,
  tableBody: MarkdownTableBody,
  tableRow: MarkdownTableRow,
  tableCell: MarkdownTableCell,
  thematicBreak: MarkdownBreakLine
};

const Markdown = (props) => (
  <ReactMarkdown
    escapeHtml={false}
    renderers={renderers}
    plugins={[[gfm]]}
    {...props}
  />
);

export default Markdown;
