import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useOnline } from 'Devices/store';
import useDeferred from 'hooks/useDeferred';
import StringVariable from './StringVariable';
import NumberVariable from './NumberVariable';
import BooleanVariable from './BooleanVariable';

import useSetVariable from '../hooks/useSetVariable';

import { schemaState, setVarPendingAtom, valueSelector } from '../store';

const Variable = ({ deviceId, variable }) => {
  const value = useRecoilValue(valueSelector(variable));
  const { properties } = useRecoilValue(schemaState);
  const pendingSetVariable = useRecoilValue(setVarPendingAtom(variable));
  const {
    title,
    description,
    type,
    writable
  } = properties[variable];
  const [online] = useOnline();
  const [setVariable] = useSetVariable(deviceId, variable);

  const pending = useDeferred(pendingSetVariable, 1500);

  const Input = useMemo(() => ({
    string: StringVariable,
    number: NumberVariable,
    integer: NumberVariable,
    boolean: BooleanVariable
  }[type]), [type]);

  const tooltip = useMemo(() => {
    if (!online) {
      return 'You cannot set variable for offline device';
    }

    if (!writable) {
      return 'You cannot set read-only variable';
    }

    return '';
  }, [online]);

  return (
    <Input
      tooltip={tooltip}
      label={title ?? variable}
      description={description}
      value={value}
      onChange={setVariable}
      readOnly={!online || !writable}
      pending={pending}
    />
  );
};

Variable.propTypes = {
  deviceId: PropTypes.string.isRequired,
  variable: PropTypes.string.isRequired,
};

export default Variable;
