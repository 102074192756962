import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useResults } from 'Devices/store';

const DevicesEmpty = () => {
  const { shape, palette } = useTheme();
  const [{ query }] = useResults();
  return (
    <Box
      textAlign="center"
      display="flex"
      flex="auto"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box p={2}>
        {query
          ? (<Typography color="textSecondary" variant="h5">Looks like there are no devices you are looking for.</Typography>)
          : (<Typography color="textSecondary" variant="h5">Looks like there are no devices yet.</Typography>)}
      </Box>
      <Box display="flex" alignItems="center">
        <Typography color="textSecondary">
          Click
        </Typography>
        <Box
          display="inline-flex"
          bgcolor={palette.primary.main}
          borderRadius={shape.borderRadius}
          color="white"
          justifyContent="center"
          alignItems="center"
          px={2}
          py={1}
          mx={1}
        >
          <Box fontSize="20px" display="flex">
            <Add fontSize="inherit" />
          </Box>
          <Box pl={1}>
            <Typography variant="body2">Add</Typography>
          </Box>
        </Box>
        <Typography color="textSecondary">
          to create your device.
        </Typography>
      </Box>
    </Box>
  );
};

export default DevicesEmpty;
