import useApiClient from './useApiClient';

const useSearchKey = (url) => {
  const apiClient = useApiClient();

  const getSearchKey = async () => {
    const searchApiKey = localStorage.getItem(`${url}/searchApiKey`);
    const expiresAt = localStorage.getItem(`${url}/expiresAt`);

    // The key should be valid for next 30 seconds
    if (searchApiKey && (Date.now() + 30000 < +expiresAt)) {
      return searchApiKey;
    }

    const { data: { apiKey, expiresIn } } = await apiClient.get(url);
    localStorage.setItem(`${url}/searchApiKey`, apiKey);
    localStorage.setItem(`${url}/expiresAt`, Date.now() + expiresIn * 1000);

    return apiKey;
  };

  return [getSearchKey];
};

export default useSearchKey;
