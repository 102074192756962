import algoliasearch from 'algoliasearch';
import useSearchKey from '../../hooks/useSearchKey';

const useIndex = () => {
  const [getSearchKey] = useSearchKey('/credentials/v1/search/projects');

  const getIndex = async () => {
    const apiKey = await getSearchKey();
    return algoliasearch(process.env.ALGOLIA_SEARCH_CLIENT_ID, apiKey).initIndex('projects');
  };

  return [getIndex];
};

export default useIndex;
