import useProjectIndex from 'hooks/useProjectIndex';
import {
  useSearchPending,
  useResults,
  useIndexError,
  useHits
} from '../store';

const useSearch = () => {
  const [getIndex] = useProjectIndex('tokens');
  const [, setSearchPending] = useSearchPending();
  const [, setResults] = useResults();
  const [, setHits] = useHits();
  const [, setError] = useIndexError();

  return {
    search: async (query) => {
      setSearchPending(true);

      try {
        const index = await getIndex();
        const res = await index.search(query);
        setResults(res);
        setHits(res.hits);
      } catch (err) {
        setError(err);
      } finally {
        setSearchPending(false);
      }
    }
  };
};

export default useSearch;
