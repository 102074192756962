import React from 'react';
import {
  Typography,
  Box,
  AppBar,
  Toolbar,
  Button,
  useTheme,
} from '@material-ui/core';
import { useCreateDialogOpen } from '../store';
import UserMenu from './UserMenu';

const ProjectsHeader = () => {
  const { spacing } = useTheme();
  const [, setCreateDialogOpen] = useCreateDialogOpen();

  const handleCreate = () => setCreateDialogOpen(true);

  return (
    <AppBar color="default" position="static">
      <Toolbar>
        <Box display="flex" alignItems="center" flex="auto">
          <Typography variant="h6">Projects</Typography>
          <Box flex="auto" />
          <Box px={2} width={spacing(22)}>
            <Button fullWidth variant="contained" color="primary" onClick={handleCreate}>Create Project</Button>
          </Box>
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ProjectsHeader;
