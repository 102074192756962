import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import useProjectId from 'hooks/useProjectId';
import { useSnackbarMessage } from 'Snackbar/store';
import {
  useDisplayName,
} from 'Projects/store';
import {
  useSavePending,
} from '../store';

const useSave = () => {
  const projectId = useProjectId();
  const firebase = useFireplaceFirebase();
  const [displayName] = useDisplayName();
  const [, setSavePending] = useSavePending();
  const [, setSnackbarMessage] = useSnackbarMessage();

  return {
    save: async () => {
      setSavePending(true);
      try {
        await firebase.firestore()
          .doc(`projects/${projectId}`)
          .set({ displayName });
        setSnackbarMessage('Project saved');
      } catch (err) {
        setSnackbarMessage('Could not save device. Please try again');
      } finally {
        setSavePending(false);
      }
    },
  };
};

export default useSave;
