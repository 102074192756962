import { useEffect } from 'react';
import useProjectId from 'hooks/useProjectId';
import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';
import { useId } from 'Devices/store';

import { entriesAtom, filterAtom } from '../store';

const useLogs = () => {
  const [deviceId] = useId();
  const projectId = useProjectId();
  const firebase = useFireplaceFirebase();
  const filter = useRecoilValue(filterAtom);
  const setEntries = useSetRecoilState(entriesAtom);
  const [, setSnackbarMessage] = useSnackbarMessage();

  useEffect(() => {
    const re = new RegExp(filter.trim(), 'i');

    if (!deviceId) {
      return;
    }

    firebase.firestore()
      .doc(`projects/${projectId}/devices/${deviceId}/props/logs`)
      .onSnapshot({
        next: (snapshot) => {
          const { entries } = snapshot.data();
          setEntries((current) => entries
            .filter(({ jsonPayload }) => re.test(jsonPayload.message))
            .filter(({ insertId }) => !current.some((curr) => curr.insertId === insertId))
            .map(({ timestamp, ...entry }) => ({ ...entry, timestamp: new Date(timestamp.seconds * 1000).toUTCString() }))
            .concat(current)
          );
        },
        error: () => setSnackbarMessage('Could subscribe to device logs.')
      });
  }, [deviceId, filter]);
};

export default useLogs;
