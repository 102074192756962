import useProjectIndex from 'hooks/useProjectIndex';
import { useFindOneResult, useIndexError, useFindOnePending } from '../store';

const useFindOne = () => {
  const [getIndex] = useProjectIndex('devices');
  const [, setFindOnePending] = useFindOnePending();
  const [, setFindOneResult] = useFindOneResult();
  const [, setFindOneError] = useIndexError();

  return {
    findOne: async (objectID) => {
      setFindOnePending(true);

      try {
        const index = await getIndex();
        const res = await index.getObject(objectID);
        setFindOneResult(res);
      } catch (err) {
        setFindOneResult(null);
        setFindOneError(err);
      } finally {
        setFindOnePending(false);
      }
    },
  };
};

export default useFindOne;
