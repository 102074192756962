import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  AccordionActions,
  TextField,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { useNavTab } from 'ProjectSettings/store';
import { FileCopyOutlined, ExpandMore } from '@material-ui/icons';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useHistory, useParams } from 'react-router-dom';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import useRefresh from '../hooks/useRefresh';
import {
  useToken,
  useRefreshPending,
  useDisplayName,
  useDeleteDialogOpen
} from '../store';

const Token = ({ objectID, displayName }) => {
  const inputRef = useRef();
  const root = useProjectRoot();
  const history = useHistory();
  const [, setNavTab] = useNavTab();
  const [copy] = useCopyToClipboard(inputRef);
  const [refresh] = useRefresh();
  const [token, setToken] = useToken();
  const [, setDisplayName] = useDisplayName();
  const [refreshPending] = useRefreshPending();
  const [, setDeleteDialogOpen] = useDeleteDialogOpen();
  const { tokenId } = useParams();

  useEffect(() => {
    setNavTab('tokens');
  }, []);

  const handleAccordionChange = (ev, expanded) => {
    if (expanded) {
      history.replace(`${root}/settings/tokens/${objectID}`);
    } else {
      history.replace(`${root}/settings/tokens`);
    }

    setToken('');
    setDisplayName(displayName);
  };

  return (
    <Accordion expanded={objectID === tokenId} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box>
          <Typography>{displayName}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Tooltip title={token ? '' : 'Refresh token to see it'}>
          <TextField
            value={token || '*********'}
            fullWidth
            InputProps={{
              inputRef,
              readOnly: true,
              type: token ? 'text' : 'password',
              endAdornment: (
                <IconButton onClick={copy} disabled={!token}><FileCopyOutlined /></IconButton>
              )
            }}
          />
        </Tooltip>
      </AccordionDetails>
      <AccordionActions>
        <Button
          startIcon={refreshPending && <CircularProgress color="inherit" size={24} />}
          disabled={refreshPending}
          onClick={refresh}
        >
          Refresh
        </Button>
        <Button
          color="secondary"
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

Token.propTypes = {
  objectID: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired
};

export default Token;
