import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Card,
  CardActions,
  Button,
  Box,
  List,
} from '@material-ui/core';
import { useNavTab } from 'Devices/store';
import useSave from '../hooks/useSave';
import useConfig from '../hooks/useConfig';
import { namesSelector, validSelector } from '../store';
import DeviceConfigItem from './DeviceConfigItem';

const DeviceConfig = () => {
  const { deviceId } = useParams();
  const [saveConfig] = useSave(deviceId);
  const [, setNavTab] = useNavTab();
  const names = useRecoilValue(namesSelector);
  const valid = useRecoilValue(validSelector);
  useConfig(deviceId);

  useEffect(() => {
    setNavTab('config');
  }, []);

  return (
    <Box p={2}>
      <Card>
        <Box py={2}>
          <List>
            {names.map(key => (
              <DeviceConfigItem key={key} property={key} />
            ))}
          </List>
        </Box>
        <CardActions>
          <Box flex="auto" />
          <Button color="primary" variant="contained" onClick={saveConfig} disabled={!valid}>
            Save
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default DeviceConfig;
