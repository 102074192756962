import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Button,
  CardActions,
  Box
} from '@material-ui/core';
import {
  useNavTab,
  useCanSave,
} from '../store';
import useSave from '../hooks/useSave';
import DeviceDetails from './DeviceDetails';

const Details = () => {
  const [, setNavTab] = useNavTab();
  const { save } = useSave();

  const [canSaveDevice] = useCanSave();

  useEffect(() => {
    setNavTab('details');
  }, []);

  return (
    <Box p={2}>
      <Card>
        <CardContent>
          <DeviceDetails />
        </CardContent>
        <CardActions>
          <Box flex="auto" />
          <Button color="primary" variant="contained" onClick={save} disabled={!canSaveDevice}>
            Save
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default Details;
