import { atom, constSelector } from 'recoil';

export const resultsAtom = atom({
  key: 'Logs/results',
  default: {}
});

export const entriesAtom = atom({
  key: 'Logs/entries',
  default: []
});

export const nextPageTokenSelector = atom({
  key: 'Logs/nextPageToken',
  get: ({ get }) => get(resultsAtom).nextPageToken
});

export const hasMoreSelector = atom({
  key: 'Logs/hasMore',
  get: ({ get }) => !!get(nextPageTokenSelector)
});

export const filterAtom = atom({
  key: 'Logs/filter',
  default: ''
});

export const orderByAtom = constSelector('timestamp desc');
export const pageSizeAtom = constSelector(20);
export const loadMorePendingAtom = atom({
  key: 'Logs/loadMorePending',
  default: false
});
export const searchPendingAtom = atom({
  key: 'Logs/searchPending',
  default: false
});
