import useProjectIndex from 'hooks/useProjectIndex';
import {
  useResults,
  useIndexError,
  useHits,
  useLoadMorePending
} from '../store';

const useLoadMore = () => {
  const [getIndex] = useProjectIndex('devices');
  const [, setLoadMorePending] = useLoadMorePending();
  const [results, setResults] = useResults();
  const [, setError] = useIndexError();
  const [hits, setHits] = useHits();

  return {
    loadMore: async () => {
      setLoadMorePending(true);
      setError(null);

      try {
        const index = await getIndex();
        const res = await index.search(results.query, { page: results.page + 1, hitsPerPage: results.hitsPerPage });
        setResults(res);
        setHits([...hits, ...res.hits]);
      } catch (err) {
        setError(err);
      } finally {
        setLoadMorePending(false);
      }
    }
  };
};

export default useLoadMore;
