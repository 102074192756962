import { useEffect, useState } from 'react';
import useFireplaceFirebase from './useFireplaceFirebase';
import useProjectId from './useProjectId';
import useApiClient from './useApiClient';

const useAuthenticated = () => {
  const projectId = useProjectId();
  const client = useApiClient();
  const firebase = useFireplaceFirebase();
  const [isAuthenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        // wait for token refresh
        if (!firebase.auth().currentUser) {
          await new Promise((resolve, reject) => {
            const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
              unsubscribe();

              if (user) {
                resolve();
              } else {
                reject();
              }
            });
          });
        }

        firebase.auth().tenantId = projectId;

        if (projectId && firebase.auth().currentUser.tenantId !== projectId) {
          const { data: { customToken } } = await client.get(
            `/credentials/v1/projects/${projectId}/token`
          );
          firebase.auth().tenantId = projectId;
          await firebase.auth().signInWithCustomToken(customToken);
        }

        return setAuthenticated(true);
      } catch {
        return setAuthenticated(false);
      }
    })();
  }, [projectId]);

  return {
    isAuthenticated: isAuthenticated === true,
    isPending: isAuthenticated == null
  };
};

export default useAuthenticated;
