import { useSnackbarMessage } from 'Snackbar/store';
import useApiClient from 'hooks/useApiClient';
import { useParams } from 'react-router-dom';
import {
  useDeletePending,
  useDeleteDialogOpen,
  useRemoveOneHit
} from '../store';

const useDelete = () => {
  const apiClient = useApiClient();
  const [, setDeletePending] = useDeletePending();
  const [, setSnackbarMessage] = useSnackbarMessage();
  const [, setDeleteDialogOpen] = useDeleteDialogOpen();
  const { tokenId } = useParams();
  const [removeHit] = useRemoveOneHit();

  return [async () => {
    setDeletePending(true);

    try {
      await apiClient.delete(`/tokens/v1/${tokenId}`);
      setSnackbarMessage('Developer token deleted.');
      setDeleteDialogOpen(false);
      removeHit(tokenId);
    } catch (err) {
      setSnackbarMessage('Could not delete developer token. Please try again.');
      throw err;
    } finally {
      setDeletePending(false);
    }
  }];
};

export default useDelete;
