import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  AccordionActions,
  CircularProgress,
} from '@material-ui/core';
import { DragHandle, ExpandMore } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useDeferred from 'hooks/useDeferred';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import useSave from '../hooks/useSave';
import useGetOne from '../hooks/useGetOne';
import {
  accessPointsIdState,
  canSaveSelector,
  savePendingState,
  showDeleteDialogState
} from '../store';
import AccessPointDetails from './AccessPointDetails';

const Handle = SortableHandle(() => (
  <Box pr={2}>
    <DragHandle color="action" />
  </Box>
));

const AccessPoint = SortableElement(({ accessPoint: { id, ssid } }) => {
  const root = useProjectRoot();
  const history = useHistory();
  const { wifiId, deviceId } = useParams();
  const setDeleteDialogOpen = useSetRecoilState(showDeleteDialogState);
  const [save] = useSave(wifiId, deviceId);
  const pendingSave = useRecoilValue(savePendingState);
  const canSave = useRecoilValue(canSaveSelector(wifiId));
  const pending = useDeferred(pendingSave, 2000);
  const setAccessPointId = useSetRecoilState(accessPointsIdState);
  const [getOne] = useGetOne(id, deviceId);
  const urlPrefix = deviceId
    ? `${root}/devices/${deviceId}/network/wi-fi`
    : `${root}/network/wi-fi`;

  useEffect(() => {
    if (wifiId !== id) {
      return;
    }

    setAccessPointId(wifiId);
    getOne();
  }, [wifiId]);

  const handleAccordionChange = (ev, expanded) => {
    history.replace(expanded ? `${urlPrefix}/${id}` : urlPrefix);
  };

  return (
    <Accordion expanded={id === wifiId} onChange={handleAccordionChange} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box display="flex" alignItems="center">
          <Handle />
          <Typography>{ssid}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <AccessPointDetails id={id} />
        </Box>
      </AccordionDetails>
      <AccordionActions>
        <Button
          color="secondary"
          disabled={pending}
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete
        </Button>
        <Button
          startIcon={pending && <CircularProgress color="inherit" size={24} />}
          disabled={!canSave || pending}
          onClick={save}
          color="primary"
        >
          Save
        </Button>
      </AccordionActions>
    </Accordion>
  );
});

AccessPoint.propTypes = {
  accessPoint: PropTypes.object.isRequired,
};

export default AccessPoint;
