import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import AccessPoints from 'WiFi/components/AccessPoints';
import AddAccessPoint from 'WiFi/components/AddAccessPoint';
import { useNavTab } from 'Devices/store';

const DeviceWiFi = () => {
  const [, setNavTab] = useNavTab();

  useEffect(() => {
    setNavTab('wi-fi');
  }, []);

  return (
    <Box height="100%" display="flex">
      <Box display="flex" flexDirection="column" flex="auto" justifyContent="start">
        <Box display="flex" px={1} pt={1}>
          <Box flex="auto" />
          <Box>
            <AddAccessPoint />
          </Box>
        </Box>
        <AccessPoints />
      </Box>
    </Box>
  );
};

export default DeviceWiFi;
