import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import ReactJson from 'react-json-view';
import InfiniteScroller from 'react-infinite-scroller';
import Scrollable from 'components/Scrollable';
import Search from 'components/Search';
import useDeferred from 'hooks/useDeferred';

import Loader from 'components/Loader';
import { useNavTab } from 'Devices/store';
import useSearch from '../hooks/useSearch';
import useLoadMore from '../hooks/useLoadMore';
import useLogs from '../hooks/useLogs';
import {
  loadMorePendingAtom,
  searchPendingAtom,
  entriesAtom,
  hasMoreSelector,
} from '../store';


const DevicesLogs = () => {
  const [, setNavTab] = useNavTab();
  const { loadMore } = useLoadMore();
  const { search } = useSearch();
  const entries = useRecoilValue(entriesAtom);
  const hasMore = useRecoilValue(hasMoreSelector);
  const loadMorePending = useRecoilValue(loadMorePendingAtom);
  const searchPending = useRecoilValue(searchPendingAtom);
  const searchPendingDeferred = useDeferred(searchPending);
  const scrollParent = useRef();
  useLogs();

  useEffect(() => {
    setNavTab('logs');
  }, []);

  return (
    <Box height="100%" display="flex">
      <Box display="flex" flexDirection="column" flex="auto" p={1}>
        <Box display="flex">
          <Box maxWidth={400} flex="auto" flexGrow={100} p={1}>
            <Search
              onSearch={search}
              isPending={searchPendingDeferred}
              TextFieldProps={{ fullWidth: true, variant: 'standard', size: 'medium' }}
            />
          </Box>
        </Box>
        <Box py={1} display="flex" flex="auto">
          <Scrollable ref={scrollParent}>
            <Box p={1}>
              <InfiniteScroller
                hasMore={hasMore}
                loader={loadMorePending && (<Box key={1}><Loader /></Box>)}
                loadMore={loadMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => scrollParent.current}
                threshold={300}
              >
                {entries.map((entry) => (
                  <Accordion key={entry.insertId}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Box>
                        <Typography>{entry.jsonPayload.message}</Typography>
                        <Tooltip title={moment(entry.timestamp).format('LLLL')}>
                          <Typography variant="subtitle2" color="textSecondary">{moment(entry.timestamp).fromNow()}</Typography>
                        </Tooltip>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ReactJson src={entry} name={false} />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </InfiniteScroller>
            </Box>
          </Scrollable>
        </Box>
      </Box>
    </Box>
  );
};

export default DevicesLogs;
