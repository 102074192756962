import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  Card,
  CardContent,
  Box,
  CardActions,
  Button,
  TextField,
  Typography,
  Switch
} from '@material-ui/core';
import Scrollable from 'components/Scrollable';
import { useBackTo } from 'Projects/store';
import useValidation from 'hooks/useValidation';
import usePageTitle from 'Projects/hooks/usePageTitle';
import useGetOne from '../hooks/useGetOne';
import useSave from '../hooks/useSave';
import {
  webhookEnabledState,
  webhookUrlState,
  navTabState
} from '../store';

const Webhook = () => {
  const [getOne] = useGetOne();
  const [save] = useSave();
  const setNavTab = useSetRecoilState(navTabState);
  const [url, setUrl] = useRecoilState(webhookUrlState);
  const [enabled, setEnabled] = useRecoilState(webhookEnabledState);
  const [, setBackTo] = useBackTo();

  usePageTitle('Webhook');

  const [
    urlHasError,
    urlErrorMessage,
    urlValid,
    onUrlChanged,
    onUrlBlurred
  ] = useValidation(
    url,
    v => (
      (!v && 'Required')
      || (!/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,5}){0,1}([0-9]{1,5}){0,1}[.]{0,1}/.test(v)
        && 'URL should be a valid HTTP or HTTPS endpoint')
      || (v && v.trim().length > 250 && 'URL should be at most 300 characters long')
    ),
    setUrl
  );

  useEffect(() => {
    setNavTab('webhook');
    setBackTo(null);

    (async () => {
      const data = await getOne();
      setUrl(data?.url);
    })();
  }, []);

  return (
    <Scrollable>
      <Box p={2}>
        <Card>
          <CardContent>
            <Box display="flex">
              <Box>
                <Typography variant="h6" gutterBottom>Webhook</Typography>
              </Box>
              <Box flex="auto" />
              <Switch checked={enabled} onChange={e => setEnabled(e.target.checked)} />
            </Box>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>Webhook description</Typography>
            <Box pt={2} />
            <TextField
              label="URL"
              value={url}
              required
              fullWidth
              margin="dense"
              error={urlHasError}
              helperText={urlErrorMessage}
              onChange={(e) => onUrlChanged(e.target.value)}
              onBlur={onUrlBlurred}
            />
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={save}
              disabled={!urlValid}
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Scrollable>
  );
};

export default Webhook;
