import React, { useRef } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Link, useParams } from 'react-router-dom';
import InfiniteScroller from 'react-infinite-scroller';
import Scrollable from 'components/Scrollable';
import Search from 'components/Search';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import useDeferred from 'hooks/useDeferred';

import Loader from 'components/Loader';
import useSearch from '../hooks/useSearch';
import useLoadMore from '../hooks/useLoadMore';
import useReset from '../hooks/useReset';
import {
  useHasMore,
  useHits,
  useLoadMorePending,
  useSearchPending,
  useCreateDialogOpen,
} from '../store';
import DevicesEmpty from './DevicesEmpty';

const DevicesList = () => {
  const root = useProjectRoot();
  const { deviceId } = useParams();
  const { loadMore } = useLoadMore();
  const { search } = useSearch();
  const [hits] = useHits();
  const hasMore = useHasMore();
  const [loadMorePending] = useLoadMorePending();
  const scrollParent = useRef();
  const [reset] = useReset();
  const [, setCreateDialogOpen] = useCreateDialogOpen();
  const [searchPending] = useSearchPending();
  const searchPendingDeferred = useDeferred(searchPending);
  const hasEntries = !!hits.length;

  const handleOpenDialog = () => {
    reset();
    setCreateDialogOpen(true);
  };

  return (
    <Box display="flex" flexDirection="column" flex="auto">
      <Box display="flex" pt={3}>
        <Box maxWidth={400} flex="auto" flexGrow={100}>
          <Search
            onSearch={search}
            isPending={searchPendingDeferred}
            TextFieldProps={{ fullWidth: true, variant: 'standard', size: 'medium' }}
          />
        </Box>
        <Box flex="auto" pr={1} />
        <Button color="primary" variant={deviceId ? 'text' : 'contained'} onClick={handleOpenDialog} startIcon={<Add />}>Add</Button>
      </Box>
      <Box py={1} display="flex" flex="auto">
        {hasEntries
          ? (
            <Scrollable ref={scrollParent}>
              <List disablePadding>
                <InfiniteScroller
                  hasMore={hasMore}
                  loader={loadMorePending && (<Box key={1}><Loader /></Box>)}
                  loadMore={loadMore}
                  initialLoad={false}
                  useWindow={false}
                  getScrollParent={() => scrollParent.current}
                  threshold={100}
                >
                  {hits.map(({ objectID, displayName }) => (
                    <ListItem
                      button
                      key={objectID}
                      divider
                      component={Link}
                      to={`${root}/devices/${objectID}`}
                      selected={deviceId === objectID}
                    >
                      <ListItemText primary={displayName} />
                    </ListItem>
                  ))}
                </InfiniteScroller>
              </List>
            </Scrollable>
          )
          : (
            !searchPendingDeferred && <DevicesEmpty />
          )}
      </Box>
    </Box>
  );
};

export default DevicesList;
