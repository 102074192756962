import { createStore } from 'reusable';
import { useState, useRef } from 'react';

export const usePending = createStore(() => useState(false));
export const useProgress = createStore(() => useState(null));
export const useBluetoothDevice = createStore(() => {
  const deviceRef = useRef();

  return [
    deviceRef.current,
    (device) => { deviceRef.current = device; },
    () => deviceRef.current
  ];
});
export const useStatus = createStore(() => useState(''));
export const useDefaultApp = createStore(() => useState(''));
