import React, { useRef, useState, Fragment } from 'react';
import {
  Typography,
  IconButton,
  Box,
  Popover,
  Avatar,
  Button,
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import useFireplaceFirebase from 'hooks/useFireplaceFirebase';

const UserMenu = () => {
  const thingware = useFireplaceFirebase();
  const { push } = useHistory();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const menuRef = useRef();

  const { displayName, photoURL } = thingware.auth().currentUser;

  const signOut = async () => {
    await thingware.auth().signOut();

    thingware.auth().tenantId = null;
    await thingware.auth().signOut();
    push('/welcome');
  };

  return (
    <Fragment>
      <IconButton edge="start" color="inherit" onClick={() => setPopoverOpen(true)} ref={menuRef}>
        <Avatar src={photoURL} />
      </IconButton>
      <Popover
        anchorEl={menuRef.current}
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2} minWidth={140}>
          <Typography noWrap gutterBottom>{displayName}</Typography>
          <Button fullWidth startIcon={<ExitToApp />} onClick={signOut}>Sign Out</Button>
        </Box>
      </Popover>
    </Fragment>
  );
};

export default UserMenu;
