import { useSnackbarMessage } from 'Snackbar/store';

const useCopyToClipboard = (inputRef) => {
  const [, setSnackbarMessage] = useSnackbarMessage();
  const copy = () => {
    inputRef.current.focus();
    inputRef.current.select();
    document.execCommand('copy');
    setSnackbarMessage('Copied to clipboard');
  };

  return [copy];
};

export default useCopyToClipboard;
