import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Card,
  Box,
  List,
} from '@material-ui/core';
import { useNavTab } from 'Devices/store';
import useDeviceOnline from 'Devices/hooks/useDeviceOnline';
import useVariables from '../hooks/useVariables';
import { namesSelector } from '../store';
import Variable from './Variable';

const Variables = () => {
  const { deviceId } = useParams();
  const [, setNavTab] = useNavTab();
  const names = useRecoilValue(namesSelector);
  useVariables(deviceId);
  useDeviceOnline(deviceId);

  useEffect(() => {
    setNavTab('variables');
  }, []);

  return (
    <Box p={2}>
      <Card>
        <Box py={2}>
          <List disablePadding>
            {names.map(key => (
              <Variable deviceId={deviceId} key={key} variable={key} />
            ))}
          </List>
        </Box>
      </Card>
    </Box>
  );
};

export default Variables;
