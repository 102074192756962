import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import Loader from './Loader';

const Authenticated = ({ children }) => {
  const firebase = useFireplaceFirebase();

  const [isPending, setPending] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        // wait for token refresh
        if (!firebase.auth().currentUser) {
          await new Promise((resolve, reject) => {
            const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
              unsubscribe();

              if (user) {
                resolve();
              } else {
                reject();
              }
            });
          });
        }
        return setAuthenticated(true);
      } catch {
        return setAuthenticated(false);
      } finally {
        setPending(false);
      }
    })();
  }, []);

  if (isPending) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/welcome" />;
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

Authenticated.propTypes = {
  children: PropTypes.any.isRequired
};

export default Authenticated;
