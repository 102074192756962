import { atom, selector } from 'recoil';

export const searchPendingState = atom({ key: 'Apps/searchPendingState', default: false });
export const searchOnePendingState = atom({ key: 'Apps/searchOnePendingState', default: false });
export const loadMorePendingState = atom({ key: 'Apps/loadMorePendingState', default: false });
export const searchResultsState = atom({ key: 'Apps/searchResultsState', default: {} });
export const searchOneResultsState = atom({ key: 'Apps/searchOneResultsState', default: {} });
export const hitsState = atom({ key: 'Apps/hitsState', default: [] });
export const selectedTabState = atom({ key: 'Apps/selectedTabState', default: 'readme' });
export const readmePendingState = atom({ key: 'Apps/readmePendingState', default: false });
export const readmeState = atom({ key: 'Apps/readmeState', default: '' });
export const hasMoreSelector = selector({
  key: 'Apps/hasMoreSelector',
  get: ({ get }) => {
    const { page, nbPages } = get(searchResultsState);
    return (nbPages - 1) > page;
  }
});
