/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Snackbar as MuiSnackbar, IconButton, Box } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbarMessage } from './store';

const defaultProps = {
  autoHideDuration: 6000
};

const Snackbar = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useSnackbarMessage();
  const [prevMessage, setPrevMessage] = useSnackbarMessage('');

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage('');
  };

  useEffect(() => {
    if (message !== prevMessage) {
      setOpen(false);
      setPrevMessage(message);
      return;
    }

    if (message && !open) {
      setOpen(true);
      return;
    }

    if (!message) {
      setOpen(false);
    }
  }, [message, open]);

  return (
    <MuiSnackbar
      open={open}
      message={message}
      onClose={handleClose}
      {...defaultProps}
      action={(
        <Box color="white">
          <IconButton color="inherit" onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      )}
    />
  );
};

export default Snackbar;
