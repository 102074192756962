import { useId } from 'Devices/store';
import useApiClient from 'hooks/useApiClient';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  pageSizeAtom,
  orderByAtom,
  resultsAtom,
  entriesAtom,
  loadMorePendingAtom,
  nextPageTokenSelector
} from '../store';

const useGetLogs = () => {
  const [deviceId] = useId();
  const client = useApiClient();
  const pageSize = useRecoilValue(pageSizeAtom);
  const orderBy = useRecoilValue(orderByAtom);
  const nextPageToken = useRecoilValue(nextPageTokenSelector);
  const [loadMorePending, setLoadMorePending] = useRecoilState(loadMorePendingAtom);
  const setResults = useSetRecoilState(resultsAtom);
  const setEntries = useSetRecoilState(entriesAtom);

  const loadMore = async () => {
    if (loadMorePending) {
      return;
    }

    try {
      setLoadMorePending(true);
      const { data: { entries: moreEntries, ...results } } = await client.get(
        `/logs/v1/${deviceId}`,
        { params: { pageSize, orderBy, pageToken: nextPageToken } }
      );
      setResults(results);
      setEntries((current) => ([
        ...current,
        ...moreEntries.filter(({ insertId }) => !current.some((curr) => curr.insertId === insertId)),
      ]));
    } catch (err) {
      setLoadMorePending('Could not get device logs. Please try again.');
    } finally {
      setLoadMorePending(false);
    }
  };

  return { loadMore };
};

export default useGetLogs;
