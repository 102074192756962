import { useSnackbarMessage } from 'Snackbar/store';

import {
  useBluetoothDevice,
  useProgress,
  usePending
} from '../store';

import { FIREPLACE_SERVICE_UUID } from '../constants';

const useConnect = () => {
  const [bluetoothDevice, setBluetoothDevice] = useBluetoothDevice();
  const [, setSnackbarMessage] = useSnackbarMessage();
  const [, setPending] = usePending();
  const [, setProgress] = useProgress();

  return {
    connect: async () => {
      try {
        setBluetoothDevice(null);
        setProgress(null);
        setPending(true);
        setSnackbarMessage('Looking for nearby devices...');
        const device = await navigator.bluetooth.requestDevice({ filters: [{ services: [FIREPLACE_SERVICE_UUID] }] });
        setSnackbarMessage('Connecting to device...');
        await device.gatt.connect();

        setBluetoothDevice(device);
        return device;
      } finally {
        setPending(false);
        setSnackbarMessage('');
      }
    },
    disconnect: async () => {
      if (!bluetoothDevice) {
        return;
      }

      await bluetoothDevice.gatt.disconnect();
      setPending(false);
      setBluetoothDevice(null);
    }
  };
};

export default useConnect;
