import { useSnackbarMessage } from 'Snackbar/store';
import { useParams } from 'react-router-dom';
import useApiClient from 'hooks/useApiClient';
import { useToken, useRefreshPending } from '../store';

const useRefresh = () => {
  const apiClient = useApiClient();
  const [, setRefreshPending] = useRefreshPending();
  const { tokenId } = useParams();
  const [, setToken] = useToken();
  const [, setSnackbarMessage] = useSnackbarMessage();

  return [async () => {
    setRefreshPending(true);

    try {
      const { data: { token } } = await apiClient.get(`/tokens/v1/${tokenId}`);
      setToken(token);
    } catch (err) {
      setSnackbarMessage('Could not refresh developer token. Please try again.');
      throw err;
    } finally {
      setRefreshPending(false);
    }
  }];
};

export default useRefresh;
