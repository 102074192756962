import useProjectIndex from 'hooks/useProjectIndex';
import { useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';
import {
  searchPendingState,
  searchResultsState,
  hitsState
} from '../store';

const useSearch = () => {
  const [getIndex] = useProjectIndex('apps');
  const setSearchPending = useSetRecoilState(searchPendingState);
  const setResults = useSetRecoilState(searchResultsState);
  const setHits = useSetRecoilState(hitsState);
  const [, setSnackbarMessage] = useSnackbarMessage();

  const search = async (query) => {
    setSearchPending(true);

    try {
      const index = await getIndex();
      const res = await index.search(query);
      setResults(res);
      setHits(res.hits);
    } catch (err) {
      console.error(err);
      setSnackbarMessage('Could not get apps. Please try again.');
    } finally {
      setSearchPending(false);
    }
  };

  return [search];
};

export default useSearch;
