import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useDeleteDialogOpen, useDisplayName, useDeletePending } from 'DeveloperTokens/store';
import useDelete from 'DeveloperTokens/hooks/useDelete';

const DeleteTokenDialog = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useDeleteDialogOpen();
  const [displayName] = useDisplayName();
  const [deletePending] = useDeletePending();
  const [deleteToken] = useDelete();

  return (
    <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
      <DialogTitle>
        Delete {displayName}?
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete {displayName}? This action cannot be undo.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)} disabled={deletePending}>Cancel</Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={deleteToken}
          disabled={deletePending}
          startIcon={deletePending && <CircularProgress size={24} color="inherit" />}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTokenDialog;
