import { readmePendingState, readmeState } from 'Apps/store';
import useApiClient from 'hooks/useApiClient';
import { useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';

const useReadme = (appId) => {
  const client = useApiClient();
  const setReadmePending = useSetRecoilState(readmePendingState);
  const setReadme = useSetRecoilState(readmeState);
  const [, setSnackbarMessage] = useSnackbarMessage();

  const getReadme = async () => {
    try {
      setReadmePending(true);
      const { data } = await client.get(`/apps/v1/${appId}/readme`, { headers: { 'if-none-match': '' } });
      return setReadme(data);
    } catch (err) {
      if (err.response.status === 404) {
        return setReadme('');
      }

      console.error(err);
      return setSnackbarMessage('Could no get readme for the app.');
    } finally {
      setReadmePending(false);
    }
  };

  return [getReadme];
};

export default useReadme;
