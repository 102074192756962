import React from 'react';
import { Redirect } from 'react-router-dom';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import useAuthenticated from 'hooks/useAuthenticated';
import LogIn from './LogIn';

const Welcome = () => {
  const { isAuthenticated, isPending } = useAuthenticated();

  if (!isPending && isAuthenticated) {
    return (<Redirect to="/" />);
  }

  return (
    <Box display="flex" flex="auto">
      <AppBar color="default">
        <Toolbar>
          <Box flex="auto" />
          <LogIn />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Welcome;
