import useProjectId from 'hooks/useProjectId';
import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import { useGetOnePending, useGetOneResult } from '../store';

const useGetOne = () => {
  const firebase = useFireplaceFirebase();
  const projectId = useProjectId();
  const [, setGetOnePending] = useGetOnePending();
  const [, setGetOneResult] = useGetOneResult();

  return {
    getOne: async (id) => {
      setGetOnePending(true);

      try {
        const res = await firebase.firestore()
          .collection(`projects/${projectId}/devices`)
          .doc(id)
          .get();
        setGetOneResult(res.data());
        return res.data();
      } catch (err) {
        setGetOneResult(null);
        throw err;
      } finally {
        setGetOnePending(false);
      }
    },
  };
};

export default useGetOne;
