import useApiClient from 'hooks/useApiClient';
import { useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';
import {
  getOnePendingState,
  accessPointSelector
} from 'WiFi/store';

const useGetOne = (id, deviceId) => {
  const client = useApiClient();
  const setPending = useSetRecoilState(getOnePendingState);
  const setAccessPoint = useSetRecoilState(accessPointSelector(id));
  const [, seSnackbarMessage] = useSnackbarMessage();
  const url = useMemo(() => (deviceId
    ? `/wifi/v1/devices/${deviceId}/wifi/${id}`
    : `/wifi/v1/${id}`
  ), [id, deviceId]);

  const getOne = async () => {
    setPending(true);

    try {
      const { data: accessPoint } = await client.get(url);
      setAccessPoint(accessPoint);
    } catch (err) {
      console.error(err);
      seSnackbarMessage('Could not get Wi-Fi');
    } finally {
      setPending(false);
    }
  };

  return [getOne];
};

export default useGetOne;
