/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Box,
  CircularProgress
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import useDebounce from 'hooks/useDebounce';
import useDeferred from 'hooks/useDeferred';

const Search = ({
  isPending,
  placeholder,
  onSearch,
  debounce,
  TextFieldProps,
  InputProps
}) => {
  const [value, setValue] = useDebounce(isPending, onSearch, debounce);
  const pending = useDeferred(isPending);

  const handleChange = (e) => setValue(e.target.value);

  return (
    <Box display="flex" flex="auto">
      <TextField
        value={value}
        onChange={handleChange}
        InputProps={{ startAdornment: <SearchIcon />, ...InputProps }}
        placeholder={placeholder}
        fullWidth
        {...TextFieldProps}
      />
      {pending && (
        <Box px={1} display="flex" alignItems="center">
          <CircularProgress size={24} />
        </Box>
      )}
    </Box>
  );
};

Search.propTypes = {
  isPending: PropTypes.bool,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  debounce: PropTypes.number,
  InputProps: PropTypes.object,
  TextFieldProps: PropTypes.object,
};

Search.defaultProps = {
  isPending: false,
  placeholder: 'Search',
  debounce: 400,
  InputProps: {},
  TextFieldProps: {},
};

export default Search;
