import { useId } from 'Devices/store';
import { useSnackbarMessage } from 'Snackbar/store';
import useApiClient from 'hooks/useApiClient';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  pageSizeAtom,
  orderByAtom,
  resultsAtom,
  entriesAtom,
  searchPendingAtom
} from '../store';

const useSearch = () => {
  const [deviceId] = useId();
  const client = useApiClient();
  const pageSize = useRecoilValue(pageSizeAtom);
  const orderBy = useRecoilValue(orderByAtom);
  const setSearchPending = useSetRecoilState(searchPendingAtom);
  const setResults = useSetRecoilState(resultsAtom);
  const setEntries = useSetRecoilState(entriesAtom);
  const [, setSnackbarMessage] = useSnackbarMessage();

  const search = async (term) => {
    try {
      const params = { pageSize, orderBy };
      params.filter = params.filter ?? '';

      if (term) {
        params.filter += `jsonPayload.message =~ "(?i)${term}" AND `;
      }

      params.filter += `timestamp >= "${new Date(Date.now() - 1000 * 60 * 60 * 24).toISOString()}"`;

      setSearchPending(true);
      const { data: { entries, ...results } } = await client.get(`/logs/v1/${deviceId}`, { params });
      setResults(results);
      setEntries(entries);
    } catch (err) {
      setSnackbarMessage('Could not get device logs. Please try again.');
    } finally {
      setSearchPending(false);
    }
  };

  return { search };
};

export default useSearch;
