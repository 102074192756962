import { Box, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import {
  Link, Redirect, Route, Switch,
  useParams,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import Scrollable from 'components/Scrollable';
import { selectedTabState } from '../store';
import Readme from './Readme';

const App = () => {
  const { appId } = useParams();
  const root = useProjectRoot();
  const selectedTab = useRecoilValue(selectedTabState);

  return (
    <Box flex="auto" display="flex" flexDirection="column">
      <Box p={2} pb={0} display="flex" height={49}>
        <Box flex="auto" position="relative">
          <Box position="absolute" width="100%">
            <Tabs value={selectedTab} variant="scrollable" scrollButtons="auto">
              <Tab label="Readme" value="readme" component={Link} to={`${root}/apps/${appId}/readme`} />
            </Tabs>
          </Box>
        </Box>
      </Box>

      <Scrollable>
        <Switch>
          <Route path="/projects/:projectId/apps/:appId/readme">
            <Readme />
          </Route>
          <Redirect from={`${root}/apps/${appId}`} to={`${root}/apps/${appId}/readme`} />
        </Switch>
      </Scrollable>
    </Box>
  );
};

export default App;
