import algoliasearch from 'algoliasearch';
import useSearchKey from './useSearchKey';
import useProjectId from './useProjectId';

const useProjectIndex = (indexName) => {
  const projectId = useProjectId();
  const [getSearchKey] = useSearchKey(`/credentials/v1/search/projects/${projectId}`);

  const getIndex = async () => {
    const apiKey = await getSearchKey();
    return algoliasearch(process.env.ALGOLIA_SEARCH_CLIENT_ID, apiKey).initIndex(`${projectId}.${indexName}`);
  };

  return [getIndex];
};

export default useProjectIndex;
