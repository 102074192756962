import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import useDeferred from 'hooks/useDeferred';
import {
  accessPointsIdState,
  savePendingState,
  showDeleteDialogState,
  ssidSelector
} from '../store';
import useRemove from '../hooks/useRemove';

const DeleteAccessPointDialog = () => {
  const { deviceId } = useParams();
  const [deleteDialogOpen, setDeleteDialogOpen] = useRecoilState(showDeleteDialogState);
  const pendingSave = useRecoilValue(savePendingState);
  const id = useRecoilValue(accessPointsIdState);
  const ssid = useRecoilValue(ssidSelector(id));
  const [remove] = useRemove(id, deviceId);
  const pending = useDeferred(pendingSave, 2000);

  const handleDelete = async () => {
    await remove();
    setDeleteDialogOpen(false);
  };

  return (
    <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
      <DialogTitle>
        Delete {ssid}?
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete {ssid}? This action cannot be undo.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)} disabled={pending}>Cancel</Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleDelete}
          disabled={pending}
          startIcon={pending && <CircularProgress size={24} color="inherit" />}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccessPointDialog;
