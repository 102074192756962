import React, { useEffect, useState, useRef } from 'react';
import {
  Tabs,
  Tab,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Menu,
  MenuItem,
  DialogActions,
  Divider,
  ListItemIcon,
  CircularProgress,
} from '@material-ui/core';
import {
  MoreVert,
  Delete,
  Bluetooth,
  GetApp
} from '@material-ui/icons';
import {
  Switch,
  Route,
  Redirect,
  useParams,
  Link,
  useHistory
} from 'react-router-dom';
import Scrollable from 'components/Scrollable';
import { useBackTo } from 'Projects/store';
import useConnect from 'Bluetooth/hooks/useConnect';
import useConfigure from 'Bluetooth/hooks/useConfigure';
import { usePending } from 'Bluetooth/store';
import DeviceConfig from 'DeviceConfig/components/DeviceConfig';
import Variables from 'Variables/components/Variables';
import DevicesLogs from 'DeviceLogs/components/DeviceLogs';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import useDeferred from 'hooks/useDeferred';
import useDownloadJSON from 'hooks/useDownloadJSON';
import {
  useNavTab,
  useDisplayName,
  useDescription,
  useDeviceApp,
  useId,
  useGetConfigFilePending,
} from '../store';
import useGetOne from '../hooks/useGetOne';
import useDelete from '../hooks/useDelete';
import useGetConfigFile from '../hooks/useGetConfigFile';
import Details from './Details';
import DeviceWiFi from './DeviceWiFi';

const Device = () => {
  const root = useProjectRoot();
  const { deviceId } = useParams();
  const { push } = useHistory();
  const menuRef = useRef();
  const [tab] = useNavTab();
  const [, setBackTo] = useBackTo();
  const [, setId] = useId();
  const [deviceName, setDisplayName] = useDisplayName();
  const [bluetoothPending] = usePending();
  const [, setDescription] = useDescription();
  const [, setApp] = useDeviceApp();
  const { getOne } = useGetOne();
  const [deleteDevice] = useDelete();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { getConfig } = useGetConfigFile();
  const [configFilePending] = useGetConfigFilePending();
  const configFilePendingDeferred = useDeferred(configFilePending);
  const [downloadJSON] = useDownloadJSON();
  const { connect } = useConnect();
  const { configure } = useConfigure();

  useEffect(() => {
    setBackTo(`${root}/devices`);
  }, []);

  useEffect(() => {
    (async () => {
      const { app, displayName, description } = await getOne(deviceId);
      setId(deviceId);
      setApp(app.id);
      setDisplayName(displayName);
      setDescription(description);
    })();
  }, [deviceId]);

  const handleDelete = async () => {
    await deleteDevice();
    setDeleteDialogOpen(false);
    push(`${root}/devices`);
  };

  const handleDownloadConfig = async () => {
    const config = await getConfig(deviceId);
    setMenuOpen(false);
    downloadJSON(config, `${deviceId}.config.json`);
  };

  const handleConfigure = async () => {
    await connect();
    await configure();
  };

  return (
    <Box flex="auto" display="flex" flexDirection="column">
      <Box p={2} pb={0} display="flex">
        <Box flex="auto" position="relative">
          <Box position="absolute" width="100%">
            <Tabs value={tab} variant="scrollable" scrollButtons="auto">
              <Tab label="Details" value="details" component={Link} to={`${root}/devices/${deviceId}/details`} />
              <Tab label="Config" value="config" component={Link} to={`${root}/devices/${deviceId}/config`} />
              <Tab label="Variables" value="variables" component={Link} to={`${root}/devices/${deviceId}/variables`} />
              <Tab label="Logs" value="logs" component={Link} to={`${root}/devices/${deviceId}/logs`} />
              <Tab label="Wi-Fi" value="wi-fi" component={Link} to={`${root}/devices/${deviceId}/network/wi-fi`} />
            </Tabs>
          </Box>
        </Box>
        <Box>
          <IconButton onClick={() => setMenuOpen(true)} ref={menuRef}>
            <MoreVert />
          </IconButton>
          <Divider />
        </Box>
      </Box>

      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={menuRef.current}
      >
        <MenuItem onClick={() => handleDownloadConfig()} disabled={configFilePending}>
          <ListItemIcon>
            {configFilePendingDeferred ? <CircularProgress size={24} /> : <GetApp />}
          </ListItemIcon>
          <Typography variant="inherit">
            Save Config to Disk
          </Typography>
        </MenuItem>
        <MenuItem divider onClick={() => handleConfigure()} disabled={configFilePending || bluetoothPending}>
          <ListItemIcon>
            {configFilePending || bluetoothPending ? <CircularProgress size={24} /> : <Bluetooth />}
          </ListItemIcon>
          <Typography variant="inherit">
            Upload Config to Device
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => { setDeleteDialogOpen(true); setMenuOpen(false); }}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <Typography variant="inherit">
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <Scrollable>
        <Switch>
          <Route path="/projects/:projectId/devices/:deviceId/details">
            <Details />
          </Route>
          <Route path="/projects/:projectId/devices/:deviceId/variables">
            <Variables />
          </Route>
          <Route path="/projects/:projectId/devices/:deviceId/config">
            <DeviceConfig />
          </Route>
          <Route path="/projects/:projectId/devices/:deviceId/logs">
            <DevicesLogs />
          </Route>
          <Route path="/projects/:projectId/devices/:deviceId/network/wi-fi" exact>
            <DeviceWiFi />
          </Route>
          <Route path="/projects/:projectId/devices/:deviceId/network/wi-fi/:wifiId" exact>
            <DeviceWiFi />
          </Route>
          <Redirect from={`${root}/devices/${deviceId}`} to={`${root}/devices/${deviceId}/details`} />
        </Switch>
      </Scrollable>

      <Dialog open={deleteDialogOpen}>
        <DialogTitle>
          Delete {deviceName}?
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {deviceName}? This action cannot be undo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button color="secondary" variant="contained" onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Device;
