import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
} from '@material-ui/core';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import useDeferred from 'hooks/useDeferred';
import useSave from '../hooks/useSave';
import {
  canSaveSelector,
  newAccessPoint,
  savePendingState,
  showCreateDialogState
} from '../store';
import WiFiDetails from './AccessPointDetails';

const CreateAccessPointDialog = () => {
  const [open, setOpen] = useRecoilState(showCreateDialogState);
  const { deviceId } = useParams();
  const [save] = useSave(null, deviceId);
  const canSave = useRecoilValue(canSaveSelector());
  const pendingSave = useRecoilValue(savePendingState);
  const reset = useResetRecoilState(newAccessPoint);
  const pending = useDeferred(pendingSave, 2000);
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleSave = async () => {
    await save();
    reset();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          Add Wi-Fi Access Point
        </Box>
      </DialogTitle>
      <DialogContent>
        <WiFiDetails isNew />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={pending}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={!canSave || pending}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAccessPointDialog;
