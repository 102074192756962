import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import React from 'react';
import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { ReusableProvider } from 'reusable';
import theme from './theme';
import App from './App';

ReactDOM.render(
  <RecoilRoot>
    <ReusableProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ReusableProvider>
  </RecoilRoot>,
  document.getElementById('root')
);
