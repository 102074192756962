import { useState, useEffect, useRef } from 'react';

const useDeferred = (pending, debounce = 400) => {
  const [value, setValue] = useState(false);
  const timeoutRef = useRef();

  useEffect(() => {
    if (pending) {
      timeoutRef.current = setTimeout(() => setValue(true), debounce);
    } else {
      clearTimeout(timeoutRef.current);
      setValue(false);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [pending]);

  return value;
};

export default useDeferred;
