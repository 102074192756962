import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Box, Container, useMediaQuery, useTheme,
} from '@material-ui/core';
import usePageTitle from 'Projects/hooks/usePageTitle';
import AppsList from './AppsList';
import App from './App';

const Apps = () => {
  usePageTitle('Apps');
  const { breakpoints } = useTheme();
  const wide = useMediaQuery(breakpoints.up('md'));

  return (
    <Box display="flex" flex="auto">
      <Container disableGutters>
        <Box display="flex" flexDirection="column" height="100%">
          <Switch>
            <Route path="/projects/:projectId/apps" exact>
              <Box p={2} pt={1} display="flex" flex="auto">
                <AppsList />
              </Box>
            </Route>

            <Route path="/projects/:projectId/apps/:appId">
              <Box display="flex" flex="auto">
                <Box display={wide ? 'flex' : 'none'} flex="auto" width={400} p={2} pt={1}>
                  <AppsList />
                </Box>
                <Box display="flex" flexGrow={99}>
                  <App />
                </Box>
              </Box>
            </Route>
          </Switch>
        </Box>
      </Container>
    </Box>
  );
};

export default Apps;
