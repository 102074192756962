import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  descriptionSelector,
  descriptionValidationSelector,
  passwordSelector,
  passwordValidationSelector,
  ssidSelector,
  ssidValidationSelector
} from '../store';

const AccessPointDetails = ({ id }) => {
  const [ssid, setSSID] = useRecoilState(ssidSelector(id));
  const [ssidBlurred, setSsidBlurred] = useState(false);
  const { error: ssidError } = useRecoilValue(ssidValidationSelector(id));

  const [hidePassword, setHidePassword] = useState(true);
  const [passwordBlurred, setPasswordBlurred] = useState(true);
  const [password, setPassword] = useRecoilState(passwordSelector(id));
  const { error: passwordError } = useRecoilValue(passwordValidationSelector(id));

  const [descriptionBlurred, setDescriptionBlurred] = useState(true);
  const [description, setDescription] = useRecoilState(descriptionSelector(id));
  const { error: descriptionError } = useRecoilValue(descriptionValidationSelector(id));

  return (
    <Fragment>
      <TextField
        label="SSID"
        value={ssid}
        required={!id}
        disabled={!!id}
        fullWidth
        autoComplete="off"
        margin="dense"
        error={!!ssidError && ssidBlurred}
        helperText={(ssidBlurred && ssidError?.message) || 'Note: SSID cannot be changed after creation'}
        onChange={(e) => setSSID(e.target.value)}
        onBlur={() => setSsidBlurred(true)}
      />
      <TextField
        label="Password"
        value={password}
        fullWidth
        margin="dense"
        autoComplete="new-password"
        type={hidePassword ? 'password' : 'text'}
        error={!!passwordError && passwordBlurred}
        helperText={(passwordBlurred && passwordError?.message) || ' '}
        onChange={(e) => setPassword(e.target.value)}
        onBlur={() => setPasswordBlurred(true)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setHidePassword(!hidePassword)}>
                {hidePassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        label="Description"
        value={description}
        fullWidth
        margin="dense"
        error={!!descriptionError && descriptionBlurred}
        helperText={(descriptionBlurred && descriptionError?.message) || ' '}
        onChange={(e) => setDescription(e.target.value)}
        onBlur={() => setDescriptionBlurred(true)}
      />

    </Fragment>
  );
};

AccessPointDetails.propTypes = {
  id: PropTypes.string
};

AccessPointDetails.defaultProps = {
  id: null
};

export default AccessPointDetails;
