import React, { useEffect } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Container,
  Divider,
} from '@material-ui/core';
import {
  Switch,
  Route,
  Redirect,
  Link,
} from 'react-router-dom';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import {
  useBackTo
} from 'Projects/store';
import AccessPoints from 'WiFi/components/AccessPoints';
import usePageTitle from 'Projects/hooks/usePageTitle';
import AddAccessPoint from 'WiFi/components/AddAccessPoint';
import { useNavTab } from '../store';

const Network = () => {
  const root = useProjectRoot();
  const [tab] = useNavTab();
  usePageTitle('Network');
  const [, setBackTo] = useBackTo();

  useEffect(() => {
    setBackTo(null);
  }, []);

  return (
    <Box display="flex" flex="auto">
      <Container disableGutters>
        <Box flex="auto" display="flex" flexDirection="column" height="100%">
          <Box p={2} pb={0} display="flex">
            <Box flex="auto" position="relative">
              <Box position="absolute" width="100%">
                <Tabs value={tab} variant="scrollable">
                  <Tab label="Wi-Fi" value="wi-fi" component={Link} to={`${root}/network/wi-fi`} />
                </Tabs>
              </Box>
            </Box>
            <Box height={49} display="flex" flexDirection="column" justifyContent="center">
              <Switch>
                <Route path="/projects/:projectId/network/wi-fi">
                  <AddAccessPoint />
                </Route>
              </Switch>
              <Divider />
            </Box>
          </Box>

          <Switch>
            <Route path="/projects/:projectId/network/wi-fi" exact>
              <Box p={1} display="flex" flex="auto">
                <AccessPoints />
              </Box>
            </Route>
            <Route path="/projects/:projectId/network/wi-fi/:wifiId">
              <Box p={1} display="flex" flex="auto">
                <AccessPoints />
              </Box>
            </Route>
            <Redirect from={`${root}/network`} to={`${root}/network/wi-fi`} />
          </Switch>
        </Box>
      </Container>
    </Box>
  );
};

export default Network;
