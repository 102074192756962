import React, { Fragment, useEffect } from 'react';
import {
  TextField,
} from '@material-ui/core';

import useValidation from 'hooks/useValidation';

import {
  useDisplayName,
  useCanSave
} from '../store';

const ProjectDetails = () => {
  const [displayName, setDisplayName] = useDisplayName();
  const [, setCanCreate] = useCanSave();

  const [
    displayNameHasError,
    displayNameErrorMessage,
    displayNameValid,
    onDisplayNameChanged,
    onDisplayNameBlurred
  ] = useValidation(
    displayName,
    (v) => (
      (!v && 'Required')
      || (v && v.length < 4 && 'Project Name should be at least 4 symbols long')
      || (v && v.trim().length > 50 && 'Project Name should be at most 50 symbols long')
    ),
    setDisplayName
  );

  useEffect(() => {
    setCanCreate(displayNameValid);
  }, [displayNameValid]);

  return (
    <Fragment>
      <TextField
        label="Name"
        value={displayName}
        required
        fullWidth
        margin="dense"
        error={displayNameHasError}
        helperText={displayNameErrorMessage || ' '}
        onChange={(e) => onDisplayNameChanged(e.target.value)}
        onBlur={onDisplayNameBlurred}
      />
    </Fragment>
  );
};

export default ProjectDetails;
