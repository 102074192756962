import { useSnackbarMessage } from 'Snackbar/store';
import useApiClient from 'hooks/useApiClient';
import useProjectId from 'hooks/useProjectId';
import { useDeletePending } from '../store';

const useDelete = () => {
  const apiClient = useApiClient();
  const projectId = useProjectId();
  const [, setDeletePending] = useDeletePending();
  const [, setSnackbarMessage] = useSnackbarMessage();

  return [async () => {
    setDeletePending(true);

    try {
      await apiClient.delete(`/projects/v1/${projectId}`);
      setSnackbarMessage('Project deleted.');
    } catch (err) {
      setSnackbarMessage('Could not delete project. Please try again.');
      throw err;
    } finally {
      setDeletePending(false);
    }
  }];
};

export default useDelete;
