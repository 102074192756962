import React, { useRef, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import Scrollable from 'components/Scrollable';
import useSearch from '../hooks/useSearch';
import {
  useHits,
} from '../store';

const ProjectsList = () => {
  const [hits] = useHits();
  const scrollParent = useRef();
  const { search } = useSearch();

  useEffect(() => {
    search();
  }, []);

  return (
    <Box display="flex" flexDirection="column" flex="auto">
      <Box py={1} display="flex" flex="auto">
        <Scrollable ref={scrollParent}>
          <List>
            {hits.map(({ objectID, displayName }) => (
              <ListItem button key={objectID} divider component={Link} to={`/projects/${objectID}`}>
                <ListItemText primary={displayName} />
              </ListItem>
            ))}
          </List>
        </Scrollable>
      </Box>
    </Box>
  );
};

export default ProjectsList;
