import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  shape: {
    borderRadius: 8
  },
  palette: {
    primary: {
      main: '#1a73e8'
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
      indicator: {
        height: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        minWidth: '80px',

        '@media (min-width: 600px)': {
          minWidth: '120px',
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    },
    MuiTabPanel: {
      root: {
        padding: 0
      }
    },
    MuiCardActions: {
      root: {
        padding: '16px',
        paddingTop: 0,
        justifyContent: 'flex-end',
      }
    }
  },
  props: {
    MuiTabs: {
      indicatorColor: 'primary'
    },
    MuiTextField: {
      variant: 'outlined',
      size: 'small'
    },
    MuiButton: {
      disableElevation: true
    },
    MuiCheckbox: {
      color: 'primary'
    },
    MuiSwitch: {
      color: 'primary'
    }
  }
});
