import { useState } from 'react';

const useValidation = (value, validator, onChange) => {
  const [dirty, setDirty] = useState(false);
  const handleChanged = (val) => {
    setDirty(true);
    onChange(val);
  };

  const handleBlurred = () => {
    setDirty(true);
  };

  const errorMessage = validator(value);
  const hasError = !!errorMessage && dirty;
  const isValueValid = !errorMessage;

  return [
    hasError,
    hasError && errorMessage,
    isValueValid,
    handleChanged,
    handleBlurred
  ];
};

export default useValidation;
