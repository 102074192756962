import React from 'react';
import {
  Typography,
  IconButton,
  Box,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import { Menu, ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useTitle, useNavigationDrawerOpen, useBackTo } from '../store';
import UserMenu from './UserMenu';

const ProjectHeader = () => {
  const [, setDrawerOpen] = useNavigationDrawerOpen();
  const [title] = useTitle();
  const [backTo] = useBackTo();

  return (
    <AppBar color="default" position="static">
      <Toolbar>
        <Box display="flex" alignItems="center" flex="auto">
          {backTo ? (
            <IconButton edge="start" color="inherit" component={Link} to={backTo}>
              <ArrowBack />
            </IconButton>
          ) : (
            <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(true)}>
              <Menu />
            </IconButton>
          )}
          <Typography variant="h6">{title}</Typography>
          <Box flex="auto" />
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ProjectHeader;
