import React from 'react';
import {
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import {
  Box,
} from '@material-ui/core';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import ProjectHeader from 'Projects/components/ProjectHeader';

import Apps from 'Apps/components/Apps';
import Devices from 'Devices/components/Devices';
import Integrations from 'Integrations/components/Integrations';
import Network from 'Network/components/Network';
import ProjectSettings from 'ProjectSettings/components/ProjectSettings';

import Loader from 'components/Loader';
import useTenantAuth from '../hooks/useTenantAuth';
import NavigationDrawer from './NavigationDrawer';

const Project = () => {
  const root = useProjectRoot();
  const { isAuthenticated, isPending } = useTenantAuth();

  if (!isPending && !isAuthenticated) {
    return (<Redirect to="/welcome" />);
  }

  if (isPending) {
    return <Loader />;
  }

  return (
    <Box display="flex" flex="auto" flexDirection="column">
      <ProjectHeader />
      <Box flex="auto" display="flex">
        <Switch>
          <Route path="/projects/:projectId/apps" component={Apps} />
          <Route path="/projects/:projectId/devices" component={Devices} />
          <Route path="/projects/:projectId/integrations" component={Integrations} />
          <Route path="/projects/:projectId/network" component={Network} />
          <Route path="/projects/:projectId/settings" component={ProjectSettings} />

          <Redirect from="*" to={`${root}/devices`} />
        </Switch>
      </Box>
      <NavigationDrawer />
    </Box>
  );
};

export default Project;
